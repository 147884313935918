import axios from "axios";
import { baseUrl } from "@/main";

export const campaignService = {
  get: () => axios.get(`${baseUrl}/campaigns`),
  getById: (id) => axios.get(`${baseUrl}/campaigns/getCampaign/${id}`),
  getByUserId: (id) => axios.get(`${baseUrl}/campaigns/byUser/${id}`),
  create: (data, customerId) =>
    axios.post(baseUrl + `/campaigns/createCampaign/${customerId}`, data),
  update: (data, id) => axios.put(`${baseUrl}/campaigns/${id}`, data),
  delete: (id) => axios.delete(`${baseUrl}/campaigns/${id}`),
  getSuggestedKeywords: (url, customerId, data, lang, location) =>
    axios.post(
      `${baseUrl}/campaigns/suggestions/keywords?lang=${lang}&customerId=${customerId}&urlSeed=${url}&location=${location}`,
      data
    ),
  getHistories: (body) =>
    // axios.post(`${baseUrl}/api/AdHistory/GetAdHistories`, body, {
    //   timeout: 120000,
    // }), //this is for stage
    axios.post(`${baseUrl}/AdHistory/GetAdHistories`, body, {
      timeout: 600000,
    }), //this is for live

  updateCampaignName: (id, data) =>
    axios.post(`${baseUrl}/campaigns/renameCampaign/${id}/${data}`),

  toggleStatus: (id) =>
    axios.post(`${baseUrl}/campaigns/toggleCampaignStatus/${id}`),

  updateKeywords: (data, id) =>
    axios.post(`${baseUrl}/campaigns/editCampaignKeywords/${id}`, data),

  updateLocation: (id, data) =>
    axios.post(`${baseUrl}/campaigns/editCampaignLocation/${id}/${data}`),

  updateHeadlines: (id, data) =>
    axios.post(`${baseUrl}/campaigns/editCampaignAd/${id}`, data),
  validateAds: (data) =>
    axios.post(`${baseUrl}/campaigns/validateAdAssets`, data),
  checkCampaignName: (name, customerId) =>
    axios.post(`${baseUrl}/campaigns/checkCampaignName`, {
      campaignName: name,
      customerId,
    }),
  updateCampaignBudget: (id, amount) =>
    axios.post(`${baseUrl}/campaigns/updateBudget/${id}/${amount}`),
  getByCustomerId: (body) =>
    axios.post(`${baseUrl}/campaigns/getCampaigns`, body),
};
